<template>
  <b-card
    class="profile-header mb-1"
    img-top
    body-class="p-0">
    <div class="position-relative" style="height: 7.5rem">
      <div class="profile-img-container d-flex align-items-center" style="left: .7rem">
        <div class="profile-img">
          <b-img
            :src="
              cursoVersionData.imagen_presentacion[0]
                ? `https://back.blumbit.net/api/public/${cursoVersionData.imagen_presentacion[0].url}`
                : ''
            "
            rounded
            fluid
            alt="profile photo"
          />
        </div>
        <!-- profile title -->
        <div class="profile-title ml-1">
          <h4 class="mb-0">
            {{ cursoVersionData.nombre }}
          </h4>
          <span
                class="text-muted"
                :variant="`light-${resolveUserStatusVariant((cursoVersionData.publicado)?'active':'inactive')}`"
              >
            {{ cursoVersionData.curso.nombre }}
              </span> -
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant((cursoVersionData.publicado)?'active':'inactive')}`"
                class="text-capitalize"
              >
                {{ cursoVersionData.modalidad.nombre }}
              </b-badge>
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant((cursoVersionData.curso_grabado_vivo)?'active':'inactive')}`"
                class="text-capitalize"
              >
                {{ cursoVersionData.curso_grabado_vivo?'Curso Grabado con Participantes':'' }}
              </b-badge>
        </div>
        <!--/ profile title -->
      </div>
      <!--user-view-user-info-card :curso-version-data="cursoVersionData" /-->
    </div>
    <div class="profile-header-nav">
      <b-navbar toggleable="md" type="light">
        <!-- toggle button -->
        <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
          <feather-icon icon="AlignJustifyIcon" size="21" />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse id="nav-text-collapse" is-nav>
          <b-tabs pills class="profile-tabs mt-1 mt-md-1" nav-class="mb-0">
            <template #tabs-start>
              <b-nav-item role="presentation" active-class="active" exact class="font-weight-bold" :to="{ name: 'curso-version-detalle', params: { id: cursoVersionData.id } }">
                <span class="d-none d-md-block">Detalles</span>
                <feather-icon icon="InfoIcon" class="d-block d-md-none" />
              </b-nav-item>
              <b-nav-item role="presentation" active-class="active" class="font-weight-bold" :to="{ name: 'curso-version-participantes', params: { id: cursoVersionData.id } }">
                <span class="d-none d-md-block">Participantes</span>
                <feather-icon icon="UsersIcon" class="d-block d-md-none" />
              </b-nav-item>
              <b-nav-item role="presentation" active-class="active" class="font-weight-bold" :to="{ name: 'curso-version-historico', params: { id: cursoVersionData.id } }">
                <span class="d-none d-md-block">Historico</span>
                <feather-icon icon="InfoIcon" active-class="active" class="d-block d-md-none" />
              </b-nav-item>
            </template>
            <!-- edit buttons -->
            <template #tabs-end>
              <b-button variant="primary" class="ml-auto" :to="{ name: 'curso-version-edit', params: { id: cursoVersionData.id } }">
                <feather-icon icon="EditIcon" class="d-block d-md-none" />
                <span class="font-weight-bold d-none d-md-block">Editar</span>
              </b-button>
            </template>
            <!-- edit buttons -->
          </b-tabs>
        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
  </b-card>
</template>

<script>
import {
  BNavbar,
  BCard,
  BImg,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  // BRow, BCol,
  BBadge,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'
import useUsersList from '../useCursoVersionesList'

export default {
  components: {
    BNavbar,
    BCard,
    BImg,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
    // BRow, BCol,
    BBadge, // BAvatar,
  },
  props: {
    cursoVersionData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant, resolveUserStatusVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
    }
  },
}
</script>
