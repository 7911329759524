<template>
  <div id="user-profile">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="cursoVersionData === undefined"
    >
      <h4 class="alert-heading">
        Error al obtener los datos de la Version del curso
      </h4>
      <div class="alert-body">
        No se encontró ningúna Version del Curso con esta identificación de curso. Consulte la
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          lista de versiones de cursos
        </b-link>
        para ver otras versiones de cursos.
      </div>
    </b-alert>

    <template v-if="cursoVersionData">
      <b-row>
        <b-col
          id="user-profile"
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <curso-version-header :curso-version-data="cursoVersionData" />
        </b-col>
      </b-row>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <router-view :curso-version-data="cursoVersionData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import { useQuery, useResult } from '@vue/apollo-composable'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
// import userStoreModule from '../userStoreModule'
import CursoVersionHeader from './CursoVersionHeader.vue'
// import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import SHOW_CURSO_VERSION from '../../../../../graphql/cursos/curso/version/ShowCursoVersion.gql'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    CursoVersionHeader,
    // UserViewUserInfoCard,

    // InvoiceList,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const blankCursoData = {
      curso: null,
      modalidad: null,
      nombre: '',
      slug: '',
      habilidades: [],
      docentes: [],
      hora: [],
      mostrar_costo: false,
      precio_curso: [],
      publicado: false,
    }
    const cursoVersionData = ref(JSON.parse(JSON.stringify(blankCursoData)))

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const { loading, result } = useQuery(SHOW_CURSO_VERSION, () => ({ cursoVersionId: router.currentRoute.params.id }))

    watch([loading], () => {
      console.log('DATOS: ', cursoVersionData.value)
      cursoVersionData.value = useResult(result).value
    })

    return {
      loading,
      cursoVersionData,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
